
.fc .fc-button-primary {
    border-color: #ffffff;
    background-color: #3b82f6;
}

.fc .fc-button-primary:hover {
    border-color: #1d4ed8;
    background-color: #1d4ed8;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
    background-color: #1d4ed8;
    border-color: #1d4ed8;
    color: #ffffff;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button:focus {
    box-shadow: none;
}
